import { SectionPrimary } from '../../Components/Section/Section'

const Project3 = () => {
    return (
        <div>
            <SectionPrimary title={'ILUVUS'}>
                <h1>Project 3</h1>
            </SectionPrimary>
        </div>
    )
}

export default Project3
